<template>
  <div class="badge" v-if="cart.length > 0">
    {{cart.length}}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Notification badge',
  computed: {
    ...mapGetters([
      'cart'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.badge {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 900;
  fill: var(--green-color-dark );
  color: var(--green-color-dark );
  background-color: #FFFFFF;
  border-radius: 100px 100px 100px 100px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  padding: 2px 7px;
  text-align: center;
  font-size: 11px;
  position: absolute;
  top: 15px;
}
@media (min-width: 992px) {
  .badge {
    font-family: "quicksand_bold", Sans-serif;
    font-weight: 900;
    fill: var(--green-color-dark );
    color: var(--green-color-dark );
    background-color: #FFFFFF;
    border-radius: 100px 100px 100px 100px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    padding: 2px 7px;
    text-align: center;
    font-size: 11px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

</style>
